import { useEffect, useState } from 'react'

/**
 * Simple utility to render content only in client
 *
 * @param children
 * @param fallback
 * @returns {*|null}
 * @constructor
 */
const ClientRendered = ({ children, fallback = null }) => {
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    return isMounted ? children : fallback
}

export default ClientRendered
