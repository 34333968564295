import generatePersonSchemaDataFromAuthor from './generatePersonSchemaDataFromAuthor'
import blocksToText from './blocksToText'
import truncate from 'lodash/truncate'
import { getSrc } from 'gatsby-plugin-image'

/**
 * Generates a schema.org structured content object from a blogpost.
 *
 * @param post
 * @returns {object}
 */
const generateArticleSchemaDataFromPost = post => {
    const bodyAsText = blocksToText(post._rawBody, true)
    const wordCount = bodyAsText
        .split(' ')
        .filter(word => word !== '')
        .length.toString()

    const metaDescription = truncate(blocksToText(post._rawBody, true), { length: 200 })
    const publishedAt = new Date(post.publishedAt)

    const articleData = {
        '@context': 'https://schema.org',
        '@type': 'Article',
        headline: post.title,
        articleBody: bodyAsText,
        wordCount: wordCount,
        inLanguage: localeToBCP47(post.lang),
        description: metaDescription,
        datePublished: publishedAt.toISOString(),
    }

    if (post.secondaryTitle) {
        articleData.alternativeHeadline = post.secondaryTitle
    }

    if (post.author) {
        articleData.author = generatePersonSchemaDataFromAuthor(post.author)
    }

    if (post.mainImage.image) {
        articleData.image = getSrc(post.mainImage.image.asset)
    }

    return articleData
}

const localeToBCP47 = locale => {
    switch (locale) {
        case 'en':
            return 'en-GB'
        case 'fi':
            return 'fi-FI'
        case 'sv':
            return 'sv-SE'
        case 'de':
            return 'de-DE'
        default:
            return ''
    }
}

export default generateArticleSchemaDataFromPost
